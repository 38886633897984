// creating plugin
// returning an object with properties or methods
export default {
  // ***************************************************************************
  // checks if a given value is array, if not puts it in an array and returns it
  forceArray: function(value) {
    return Array.isArray(value) ? value : [value];
  },

  // checks if value is string and always returns string
  forceString: function(value) {
    return value === value + "" || value + 0 === value ? value + "" : "";
  },

  // ***************************************************************************
  // checks if a given value is array, if not returns empty array
  returnArray: function(value) {
    return Array.isArray(value) ? value : [];
  },

  // ***************************************************************************
  // checks if a value is exactly the same as the given values
  // value can be any value
  // values should be an array of values to check against, if is not an array it will be forced to array
  // returns true if any values item is egual to value, otherwise returns false
  compareValue: function(value, values) {
    var result = false;
    this.forceArray(values).forEach(function(item) {
      result = item === value ? true : result;
      return !result;
    }, this);
    return result;
  },

  // ***************************************************************************
  // getProperty returns all properties based on a path from a js object,
  // defaultValue is the value returned if nothing is found
  // path is an array containing property names as they should follow each other in the tree hierarchy
  // obj is the js object searched for the property value
  // returns found property value or default value
  getProperty: function(defaultValue, path, obj) {
    //checking if default value is undefined and setting default return value to null if it is undefined
    defaultValue = defaultValue !== undefined ? defaultValue : null;
    path = this.forceArray(path);
    const result = path.reduce(function(accumulator, currentValue) {
      return accumulator && accumulator[currentValue]
        ? accumulator[currentValue]
        : defaultValue;
    }, obj);
    return result;
  },

  // ***************************************************************************
  // checks an object if it has a property with a given value
  // path is a string or array containing path to property
  // value is the value of the property we search for
  // obj is the object we are seaching
  hasPropValue: function(path, value, obj) {
    path = this.forceArray(path);
    //window.console.log(this.getProperty(false, path, obj));
    return this.getProperty(undefined, path, obj) !== undefined;
  },

  // ***************************************************************************
  // checks each value(object) in array if it has a given a property
  // path is a string or array containing path to property
  // arr is the array we are seaching through
  // returns an array containing the property values in the found objects, if no property is found empty array is retuned
  getEachPropVal: function(path, arr) {
    path = this.forceArray(path);
    arr = this.forceArray(arr);
    var result = [];
    //var withprop = this.getEachWithProp(path, arr);
    arr.forEach(function(item) {
      var val = this.getProperty(null, path, item);
      if (val !== null) {
        result.push(val);
      }
    }, this);
    return result;
  },

  // ***************************************************************************
  // checks each value(object) in array if it has a given a property
  // path is a string or array containing path to property
  // arr is the array we are seaching through
  // returns an array containing all the found objects, if no object is found empty array is retuned
  getEachWithProp: function(path, arr) {
    path = this.forceArray(path);
    arr = this.forceArray(arr);
    return arr.filter(function(item) {
      return this.getProperty(null, path, item) !== null;
    }, this);
  },

  // ***************************************************************************
  // checks each value(object) in array if it has a given a property with a given exact value
  // path is a string or array containing path to property
  // value is the value of the property we search for
  // arr is the array we are seaching through
  // returns an array containing all the found objects, if no object is found empty array is retuned
  getEachWithPropVal: function(path, value, arr) {
    path = this.forceArray(path);
    arr = this.forceArray(arr);
    return arr.filter(function(item) {
      return this.compareValue(this.getProperty(null, path, item), value);
    }, this);
  },

  // ***************************************************************************
  // checks each value(object) in array if it has a given a property with a given exact value
  // path is a string or array containing path to property
  // value is the value of the property we search for
  // arr is the array we are seaching through
  // returns the first item containing the found object, if no object is found undefined is retuned
  getFirstWithPropVal: function(path, value, arr) {
    path = this.forceArray(path);
    arr = this.forceArray(arr);
    return arr.find(function(item) {
      return this.compareValue(this.getProperty(null, path, item), value);
    }, this);
  },

  // ***************************************************************************
  // removes all duplicate values from an array
  filterDuplicateValues: function(arr) {
    const chekedArr = this.forceArray(arr);
    const filteredArr = chekedArr.filter(function(val, index) {
      return val !== "" && chekedArr.indexOf(val) === index;
    });
    return filteredArr;
  },

  // flattens a multi dimensional array
  flattenArray: function(arr) {
    return [].concat.apply([], arr);
  },

  // deep copy objjects and arrays
  // https://javascript.plainenglish.io/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089
  deepCopy: function(inObject) {
    let outObject, value, key;

    if (typeof inObject !== "object" || inObject === null) {
      return inObject; // Return the value if inObject is not an object
    }
    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
      value = inObject[key];
      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = this.deepCopy(value);
    }
    return outObject;
  }
};
