<template>
  <div class="legal" v-if="isVisible">
    <template>
      Kérünk, vedd figyelembe, hogy szerződésedre az annak kezdete időpontjában
      hatályos biztosítási feltételek vonatkoznak.
    </template>
    <!--template v-if="hasTerms">
      Kérünk vedd figyelembe, hogy szerződésedre az annak kezdete időpontjában
      hatályos általános biztosítási feltételek vonatkoznak.
    </template>
    <template v-if="hasAdditionalTerms">
      Kérünk vedd figyelembe, hogy szerződésedre az annak kezdete időpontjában
      hatályos kiegészítő biztosítási feltételek vonatkoznak.
    </template>
    <template v-if="hasSpecialTerms">
      Kérünk vedd figyelembe, hogy szerződésedre az annak kezdete időpontjában
      hatályos különös biztosítási feltételek vonatkoznak.
    </template-->
    <!--template>
      Keresés esetén kérünk vedd figyelembe, hogy szerződésedre az annak kezdete
      időpontjában hatályos általános, különös illetve kiegészítő biztosítási
      feltételek vonatkoznak.
    </template-->
  </div>
</template>

<script>
import H from "@/plugins/ObjectHandler.js";
export default {
  name: "Legal",
  created: function() {},

  props: {
    labels: Array
  },

  // component data properties
  data: function() {
    return {
      // if the LABELS defined here are detected legal notice must be shown for document
      labelsHu: {
        terms: "feltételek",
        generalTerms: "általános biztosítási feltételek",
        additionalTerms: "kiegészítő biztosítási feltételek",
        specialTerms: "különös biztosítási feltételek"
      },
      // if the LABELS defined here are detected legal notice must be shown for document
      labelsEn: {
        terms: "conditions",
        generalTerms: "terms and conditions",
        additionalTerms: "supplemental insurance terms and conditions"
      }
    };
  },

  // component computed values
  computed: {
    hasTerms: function() {
      if (H.compareValue(this.labelsHu.terms, this.labels)) return true;
      if (H.compareValue(this.labelsHu.generalTerms, this.labels)) return true;
      if (H.compareValue(this.labelsEn.terms, this.labels)) return true;
      if (H.compareValue(this.labelsEn.generalTerms, this.labels)) return true;
      return false;
    },
    hasAdditionalTerms: function() {
      if (H.compareValue(this.labelsHu.additionalTerms, this.labels))
        return true;
      if (H.compareValue(this.labelsEn.additionalTerms, this.labels))
        return true;
      return false;
    },
    hasSpecialTerms: function() {
      if (H.compareValue(this.labelsHu.specialTerms, this.labels)) return true;
      return false;
    },
    isVisible: function() {
      return this.hasTerms || this.hasAdditionalTerms || this.hasSpecialTerms;
    }
  },

  filters: {},

  methods: {},

  watch: {}
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.legal {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 0.75em;
  line-height: 100%;
  margin: 0px;
  padding: 0.5em 0px 0em 0px;
  max-width: 30em;
  text-align: left;
  color: $colorGray;
}
</style>
