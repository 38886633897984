<template>
  <slide-up-down
    :active="visibleSuggestions"
    :duration="500"
    class="uins-doc-search-suggestion"
  >
    <div
      class="uins-suggested-label"
      v-for="(label, index) in labels"
      v-bind:key="'label' + index"
      v-on:click="clickSuggestion(label)"
    >
      {{ label }}
    </div>
  </slide-up-down>
</template>

<script>
import { EventBus } from "@/main.js";
//import objectHandler from "@/plugins/ObjectHandler.js";
//import DocumentsHandler from "@/plugins/DocumentsHandler.js";

export default {
  name: "Suggestions",
  components: {},
  created: function() {},
  props: {
    labels: Array,
    search: String,
    appId: String
  },
  // component data properties
  data: function() {
    return {};
  },

  // component computed values
  computed: {
    hasSuggestions: function() {
      return this.labels.length > 0;
    },

    firstSuggestion: function() {
      const myLabel = this.hasSuggestions ? this.labels[0] : "";
      return myLabel + "" === myLabel ? myLabel.toLowerCase() : "";
    },

    hasSearchedSuggestion: function() {
      return (
        this.labels.length === 1 &&
        this.firstSuggestion === this.search.toLowerCase()
      );
    },

    visibleSuggestions: function() {
      return this.hasSuggestions && !this.hasSearchedSuggestion;
    }
  },

  filters: {},

  methods: {
    clickSuggestion: function(word) {
      const strWord = word === word + "" ? word : "";
      //this.$log.debug("word", word, strWord, this.appId);
      if (strWord !== "") {
        EventBus.$emit("searchSuggestion", strWord.toLowerCase(), this.appId);
      }
    }
  },

  watch: {}
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.uins-doc-search-suggestion {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0.25em auto 0.25em auto;
  padding: 0px;
  width: auto;
  height: auto;

  .uins-suggested-label {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    font-size: 0.85em;
    line-height: 100%;
    margin: 0.25em;
    padding: 0.4em 1em 0.25em 1em;
    width: auto;
    height: auto;
    color: $colorWhite;
    background-color: $colorGrayLight;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: $colorGray;
    }
  }
}
</style>
