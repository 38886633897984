import Vue from "vue";
import App from "@/App.vue";
import store from "@/store/store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLogger from "vuejs-logger";
import SlideUpDown from "vue-slide-up-down";
// import global component files from current project, and register them
import Loader from "@/components/Loader.vue";
import VueContainerHandler from "@/plugins/VueContainerHandler.js";
import VueContainerData from "@/plugins/VueContainerData.js";

// register components
Vue.component("slide-up-down", SlideUpDown);
Vue.component("Loader", Loader);

//create settings for vendor components
const isProduction = process.env.NODE_ENV === "production";
const vueLoggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

// register vendor components globally,
// this way no import is needed befor using in Vue
Vue.use(VueLogger, vueLoggerOptions);
Vue.use(VueAxios, axios);

// creating the event bus instance
export const EventBus = new Vue();

Vue.config.productionTip = false;

// **************************************
// creating multiple vue instances
const containerIds = VueContainerHandler.getContainerIds();
var appInstances = [];
for (var k = 0; k < containerIds.length; k++) {
  var id = containerIds[k];
  var container = document.getElementById(id);
  var data = VueContainerData.ContainerData(container, id);
  //console.log(data);
  /*
  //var parameters = VueContainerHandler.getParameters(container);
  var labels = VueContainerHandler.getLabels(container);
  var ids = VueContainerHandler.getIds(container);
  //console.log(ids, labels);
  var data = {
    id: containerIds[k],
    search: VueContainerHandler.getElemAttribute(container, "data-search"),
    filterIds: VueContainerHandler.getElemAttribute(container, "data-ids"),
    filterLabels: VueContainerHandler.getElemAttribute(
      container,
      "data-labels"
    ),
    categories: VueContainerHandler.getElemAttribute(
      container,
      "data-categories"
    )
  };
  */
  if (data.valid === true) {
    var appInstance = new Vue({
      el: "#" + containerIds[k],
      store: store,
      data: data,
      render: h => h(App)
    });
    appInstances.push(appInstance);
  }
}
export default appInstances;

/*
new Vue({
  store,
  render: h => h(App)
}).$mount("#app");
*/
