// creates data object for containers

import Handler from "@/plugins/VueContainerHandler.js";
import UrlHelper from "@/plugins/UrlHelper.js";

export default {
  idSmartCube: "SmartCube",
  idSearch: "Search",

  ContainerData: function(elem, id) {
    const ids = Handler.getParamterValue(elem, Handler.classDocumentId);
    const filterIds = this.processIds(ids);
    const filterLabels = this.processLabels(elem);
    const categories = Handler.getParamterValue(elem, Handler.classCategories);
    const search = this.hasSearch(ids);
    const valid = this.isValid(ids);
    const showInfo = UrlHelper.getLocationParamValue("showInfo");
    return {
      id: id,
      valid: valid,
      search: search,
      filterIds: filterIds,
      filterLabels: filterLabels,
      categories: categories,
      showInfo: showInfo
    };
  },

  isValid: function(idArray) {
    return idArray.join(",").search(this.idSmartCube) >= 0;
  },

  hasSearch: function(idArray) {
    return idArray.join(",").search(this.idSearch) >= 0;
  },

  processLabels: function(elem) {
    const labels = Handler.getParamterValue(elem, Handler.classLabels);
    const orConditions = labels.map(item => item.split("|"));
    return orConditions.reduce((acc, val) => acc.concat(val), []);
  },

  processIds: function(idArray) {
    return idArray.filter(item => {
      return item !== "" && item !== this.idSmartCube && item !== this.idSearch;
    });
  }
};
