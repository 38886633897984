import axios from "axios";
import store from "@/store/store";

// API base settings
export default function(baseURL) {
  const http = axios.create({
    // base url that we are connecting to
    baseURL: baseURL,
    //baseURL === baseURL + "" ? baseURL : process.env.VUE_APP_API_WEB_BASEURL,
    // no authentication is needed
    withCredentials: false,
    headers: {
      // the MIME-type the browser is going to look for
      Accept: "text/json",
      // the data type we are sending send needed for POST
      // IMPORTANT set this value or the data won't get through !!!
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
  });

  //interceptor for detecting requests in progress
  http.interceptors.request.use(
    //handling start loading
    function(config) {
      store.commit("loader/startLoading");
      return config;
    },

    // handling error
    // commiting loader/stopLoading with slight delay
    // (this way if a load starts immediatly after a load finished the loading status will not flip fast )
    function(error) {
      window.setTimeout(function() {
        store.commit("loader/stopLoading");
      }, 5100);
      return Promise.reject(error);
    }
  );

  //interceptor for detecting requests in progress
  http.interceptors.response.use(
    //handling  successfully finished loading
    // commiting loader/stopLoading with slight delay
    // (this way if a load starts immediatly after a load finished the loading status will not flip fast )
    function(response) {
      window.setTimeout(function() {
        store.commit("loader/stopLoading");
      }, 100);
      return response;
    },

    //handling error
    // commiting loader/stopLoading with slight delay
    // (this way if a load starts immediatly after a load finished the loading status will not flip fast )
    function(error) {
      window.setTimeout(function() {
        store.commit("loader/stopLoading");
      }, 100);
      return Promise.reject(error);
    }
  );

  return http;
}
