// Helps handling multiple containers for vue
export default {
  // classContainer: "union-documents",
  classContainer: "documents",
  classLabels: "labels",
  classDocumentId: "documentid",
  classCategories: "categories",
  idGeneratorPrefix: "uins-doc-",
  idGeneratorCount: 0,

  // returns the container elements
  getContainerElements: function() {
    return document.getElementsByClassName(this.classContainer);
  },

  // returns array with container IDs
  getContainerIds: function() {
    const containers = this.getContainerElements();
    var containerIds = [];
    for (var i = 0; i < containers.length; i++) {
      containerIds.push(this.requestElemId(containers[i]));
    }
    return containerIds;
  },

  // returns the named attribute of an element
  getElemAttribute: function(elem, attributeName) {
    var attrValue = null;
    if (this.isDomElement(elem) && elem.hasAttribute(attributeName)) {
      const tmp = elem.getAttribute(attributeName);
      attrValue = tmp === "" ? attrValue : tmp;
    }
    return attrValue;
  },

  // returns the <span class="parameter"></span> child elements
  getParameters: function(elem) {
    const childrenArray = Array.from(elem.children); // make Array from his children
    const parametersArray = childrenArray.filter(item => {
      return this.isDomElement(item) && item.className.search("parameter") >= 0;
    });
    return parametersArray;
  },

  getParamtersNamed: function(elem, name) {
    if (name !== name + "") return [];
    return this.getParameters(elem).filter(item => {
      return item.className.search(name) >= 0 && this.hasInnerText(item);
    });
  },

  getParamterValue: function(elem, name) {
    return this.getParamtersNamed(elem, name).map(item => {
      return "innerText" in item
        ? item.innerText.replace(/[\u202F\u00A0\u2000\u2001\u2003]/g, " ")
        : "";
    });
  },

  hasInnerText: function(elem) {
    return (
      "innerText" in elem &&
      elem.innerText === elem.innerText + "" &&
      elem.innerText !== ""
    );
  },

  /*
  // returns an id array
  getIds: function(elem) {
    return this.getParamtersNamed(elem, this.classDocumentId).map(item => {
      return "innerText" in item ? item.innerText : "";
    });
  },

  // returns the labels
  getLabels: function(elem) {
    return this.getParamtersNamed(elem, this.classLabels).map(item => {
      return "innerText" in item ? item.innerText : "";
    });
  },
  */

  // returns an element id for a dom elem (if the element does not have one creates a unique element id)
  requestElemId: function(elem) {
    var id = "";
    if (this.isDomElement(elem)) {
      id = elem.id;
      if (!this.isValidElemId(elem.id)) {
        id = this.generateUniqueElemId();
        elem.id = id;
      }
    }
    return id;
  },

  // checks if given parameter is a DOm element object
  isDomElement: function(elem) {
    return !!(elem && "nodeType" in elem && elem.nodeType === 1);
  },

  // checks if the given parameter is a valid DOM element id
  isValidElemId: function(id) {
    return typeof id === "string" && id !== "" && id.length > 0;
  },

  // generates a new unique element id in the current document context
  generateUniqueElemId: function() {
    while (
      document.getElementById(
        this.idGeneratorPrefix + this.idGeneratorCount
      ) !== null
    ) {
      this.idGeneratorCount++;
    }
    return this.idGeneratorPrefix + this.idGeneratorCount;
  }
};
