// document handler plugin
// methods for handling a document

// importing
import stringHandler from "@/plugins/StringHandler.js";
import objHandler from "@/plugins/ObjectHandler.js";
import filesHandler from "@/plugins/FilesHandler.js";

//Methods
export default {
  // checks if document has any label from an array of labels
  // returns true or false
  docHasAllLabels: function(doc, labelsArray) {
    const docLabels = objHandler.getProperty([], "labels", doc);
    const labelsChecked = objHandler.forceArray(labelsArray);
    const hasLabels = labelsChecked.every(label => {
      return docLabels.indexOf(label) >= 0;
    });
    return hasLabels;
  },

  // checks if document has any id, from an array of Id's
  // returns true or false
  docHasAnyId: function(doc, idsArray) {
    const docId = objHandler.getProperty("", "id", doc);
    const idsChecked = objHandler.forceArray(idsArray);
    const hasId = docId !== "" && idsChecked.indexOf(docId + "") >= 0;
    return hasId;
  },

  // search document object title for searchText
  searchDocumentTitle: function(docObject, searchText) {
    const title = objHandler.getProperty("", "title", docObject);
    return stringHandler.searchStringForText(title, searchText);
  },

  // search document object title for searchText
  searchDocumentLabels: function(docObject, searchText) {
    const title = objHandler.getProperty("", "title", docObject);
    const regex = RegExp(stringHandler.escapeRegExp(searchText), "gi");
    return regex.test(title);
  },

  // returns all years from a document's files
  getYearsInDocument: function(docObject) {
    const files = objHandler.getProperty([], "files", docObject);
    return filesHandler.getYears(files);
  }
};
