<template>
  <div class="uins-doc-search-suggestion-years">
    <div class="button" v-on:click="toggleChoices">
      {{ buttonTextBig }}<span class="label">{{ buttonTextSmall }}</span>
    </div>
    <slide-up-down :active="visibleSuggestions" :duration="500" class="choices">
      <div
        class="choice"
        :class="{ active: isSelected(null) }"
        v-on:click="clickChoice(null)"
      >
        <span class="suffix">
          {{ allYears }}
        </span>
      </div>
      <div
        class="choice"
        :class="{ active: isSelected(year) }"
        v-for="(year, index) in years"
        v-bind:key="'year' + index"
        v-on:click="clickChoice(year)"
      >
        {{ year
        }}<span class="suffix">{{
          makeYearSuffix(year) + " " + inEffect
        }}</span>
      </div>
    </slide-up-down>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import textGenerator from "@/plugins/TextGenerator.js";
//import objectHandler from "@/plugins/ObjectHandler.js";
//import DocumentsHandler from "@/plugins/DocumentsHandler.js";

export default {
  name: "SuggestedYears",
  components: {},
  created: function() {},
  props: {
    years: Array,
    selectedYear: Number,
    appId: String
  },

  // component data properties
  data: function() {
    return {
      allYears: "Bármikor hatályos",
      inEffect: "hatályos",
      buttonClicked: false
      //selectedYear: null
    };
  },

  // component computed values
  computed: {
    visibleSuggestions: function() {
      return this.years.length > 0 && this.buttonClicked;
    },
    buttonTextBig: function() {
      return this.selectedYear !== null ? this.selectedYear + "" : "";
    },
    buttonTextSmall: function() {
      return this.selectedYear !== null
        ? this.makeYearSuffix(this.selectedYear) + " " + this.inEffect
        : this.allYears;
    } /*,
    yearLength: function() {
      if (this.years.indexOf(this.selectedYear) < 0) {
        this.emitYearChange(null);
      }
      return this.years.length;
    }*/
  },

  filters: {},

  methods: {
    clickChoice: function(year) {
      this.toggleChoices();
      this.emitYearChange(year);
    },

    emitYearChange: function(year) {
      const num = year + 0 === year ? year : null;
      if (num !== this.selectedYear) {
        //this.selectedYear = year + 0 === year ? year : null;
        EventBus.$emit("narrowSearchByYear", num, this.appId);
      }
    },

    toggleChoices() {
      this.buttonClicked = this.buttonClicked === false ? true : false;
    },

    isSelected: function(val) {
      return val === this.selectedYear;
    },

    makeYearSuffix: function(year) {
      return "-" + textGenerator.getNumberSuffixHun(year, "ban");
    }
  },

  //watchers
  watch: {
    /*yearsLength: function(changedYearsLength) {
      this.$log.debug("years changed", this.yearsLength, changedYearsLength);
    }*/
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally
.uins-doc-search:focus + .uins-doc-search-suggestion-years {
  .button {
    border-color: $colorRed;
    border-left: none;
  }
}

.uins-doc-search-suggestion-years {
  position: absolute;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  top: 0px;
  right: 0px;
  margin: 0px;
  padding: 0px;
  width: 5em;
  height: 2.625em;
  text-align: center;
  z-index: 10;

  .button {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: inherit;
    height: inherit;
    padding: 0.5em 0.5em 0.25em 0.5em;
    margin: 0px;
    font-size: 0.85em;
    font-family: $font-family-normal;
    line-height: 100%;
    color: $colorWhite;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-transform: uppercase;
    border: 1px solid $colorGrayLight;
    border-left: none;
    background-color: $colorGrayLight;
    cursor: pointer;

    .label {
      position: relative;
      display: inline;
      font-size: 0.75em;
      line-height: 100%;
      margin: 0px;
      padding: 0px;
    }

    &:hover {
      background-color: $colorGray;
    }
  }

  .choices {
    position: absolute;
    display: block;
    box-sizing: border-box;
    top: 2.625em;
    right: 0px;
    width: inherit;
    height: auto;
    margin: -1px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    background-color: $colorWhite;
    box-shadow: 0px 0.25em 0.25em rgba(0, 0, 0, 0.5);
    //border: 1px solid $colorGrayLight;
    overflow: hidden;

    .choice {
      position: relative;
      display: block;
      box-sizing: border-box;
      font-size: 0.85em;
      font-family: $font-family-normal;
      line-height: 100%;
      text-transform: uppercase;
      color: $colorGray;
      width: auto;
      height: auto;
      min-height: 2.625em;
      margin: 0px 1px 0px 1px;
      padding: 0.35em 0.5em 0.25em 0.5em;
      border-top: 1px solid $colorGrayLighter;
      cursor: pointer;

      &:first-child {
        border-top: none;
      }

      .suffix {
        position: relative;
        display: inline;
        line-height: 100%;
        font-size: 0.75em;
        margin: 0px;
        padding: 0px;
      }
    }
  }

  .uins-suggestion-year {
    position: absolute;
    display: block;
    box-sizing: border-box;
    font-size: 0.85em;
    font-family: $font-family-normal;
    line-height: 100%;
    margin: 2px;
    padding: 0.35em 0.5em 0.25em 0.5em;
    width: auto;
    height: auto;
    color: $colorWhite;
    //background-color: $colorGrayLight;
    border-radius: 3px;
    text-transform: uppercase;
    //border-left: 1px solid $colorWhite;
    cursor: pointer;

    &.uins-suggestion-year.active {
      background-color: $colorGray;
    }

    &:hover {
      background-color: $colorGray;
    }

    .suffix {
      position: relative;
      display: inline;
      line-height: 100%;
      font-size: 0.75em;
      margin: 0px;
      padding: 0px;
    }
  }
}
</style>
