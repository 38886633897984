<template>
  <div class="uins-doc">
    <!--future releases-->
    <template v-for="(file, index) in docFilesSorted.future">
      <DocumentFile
        v-if="fileIsForYear(file)"
        v-bind:key="'future' + index"
        v-bind:file="file"
        v-bind:docinfo="docInfo"
        data-inEffect="future"
        class="planned"
      ></DocumentFile>
    </template>

    <!--current release-->

    <div class="uins-current">
      <template v-for="(file, index) in docFilesSorted.current">
        <DocumentFile
          v-if="fileIsForYear(file)"
          v-bind:key="'current' + index"
          v-bind:file="file"
          v-bind:docinfo="docInfo"
          data-inEffect="current"
        ></DocumentFile>
      </template>

      <template v-if="yearFilterIsEnabled">
        <template v-for="(file, index) in docFilesSorted.expired">
          <DocumentFile
            v-if="fileIsForYear(file)"
            v-bind:key="'expired' + index"
            v-bind:file="file"
            v-bind:docinfo="docInfo"
            data-inEffect="archive"
            class="archive"
          ></DocumentFile>
        </template>
      </template>

      <!--archive-->
      <ArchiveButton
        v-if="visibleArchiveButton"
        @archiveButton-clicked="archiveButtonClick"
        v-bind:years="expiredYears"
      ></ArchiveButton>

      <slide-up-down
        v-if="visibleArchiveButton"
        :active="visibleArchive"
        :duration="500"
        class="doc-archive"
      >
        <!--div class="doc-archive clearfix"-->
        <template v-for="(file, index) in docFilesSorted.expired">
          <DocumentFile
            v-bind:key="'archive' + index"
            v-bind:file="file"
            v-bind:docinfo="docInfo"
            data-inEffect="archive"
            class="archive"
          ></DocumentFile>
        </template>
        <!--/div-->
      </slide-up-down>
    </div>
  </div>
</template>

<script>
import DocumentFile from "@/components/DocumentFile.vue";
import ArchiveButton from "@/components/ArchiveButton.vue";

import objHandler from "@/plugins/ObjectHandler.js";
//import docsHandler from "@/plugins/DocumentsHandler.js";
import filesHandler from "@/plugins/FilesHandler.js";
import fileHandler from "@/plugins/FileHandler.js";

export default {
  name: "Document",
  components: {
    DocumentFile: DocumentFile,
    ArchiveButton: ArchiveButton
  },
  created: function() {},
  props: {
    doc: Object,
    filterYear: Number
  },
  // component data properties
  data: function() {
    return {
      archiveIsVisible: false
    };
  },

  // component computed values
  computed: {
    docInfo: function() {
      return {
        docId: this.docId,
        title: this.docTitle,
        labels: this.docLabels,
        showInfo: this.showInfo,
        fromWeb: this.isFromWeb
      };
    },

    isFromWeb: function() {
      return objHandler.getProperty(false, "fromWeb", this.doc);
    },

    // returns true if source and id should be shown
    showInfo: function() {
      return this.$root.$data.showInfo === "yes";
    },

    docTitle: function() {
      return objHandler.getProperty("", "title", this.doc);
    },

    docFiles: function() {
      return objHandler.getProperty([], "files", this.doc);
    },

    hasFiles: function() {
      return this.docFiles.length > 0;
    },

    docFilesOrdered: function() {
      return filesHandler.orderFiles(this.docFiles);
    },

    docFilesSorted: function() {
      return filesHandler.sortFiles(this.docFilesOrdered);
    },

    docLabels: function() {
      return objHandler.getProperty([], "labels", this.doc);
    },

    docId: function() {
      return objHandler.getProperty("", "id", this.doc);
    },

    visibleArchive: function() {
      return (
        this.archiveIsVisible &&
        this.docFilesSorted.expired.length > 0 &&
        !this.yearFilterIsEnabled
      );
    },

    visibleArchiveButton: function() {
      return !this.yearFilterIsEnabled && this.hasArchive;
    },

    hasArchive: function() {
      return this.docFilesSorted.expired.length > 0;
    },

    yearFilterIsEnabled: function() {
      return this.filterYear !== null && isFinite(this.filterYear);
    },

    expiredYears: function() {
      const years = filesHandler.getYears(this.docFilesSorted.expired);
      const noDuplicates = objHandler.filterDuplicateValues(years);
      const ordered = noDuplicates.slice().sort(function(a, b) {
        return b - a;
      });
      return ordered;
    }
  },

  filters: {},

  methods: {
    archiveButtonClick: function(val) {
      //this.$log.debug("archive button clicked: ", val);
      this.archiveIsVisible = val;
    },

    fileIsForYear: function(file) {
      //this.$log.debug(file, this.filterYear);
      return this.yearFilterIsEnabled
        ? fileHandler.hasYear(file, this.filterYear)
        : true;
    }
  },

  watch: {}
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally
.uins-doc {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0px auto 0px auto;
  padding: 0px;
  width: auto;
  height: auto;
  //min-height: 4.5em;

  .uins-current {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1em;
    margin: 0px;
    padding: 0px;
    width: auto;
    height: auto;
    //min-height: 0px;
  }

  .uins-archive {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1em;
    margin: 0px;
    padding: 0px;
    width: auto;
    height: auto;
    //min-height: 0px;
  }

  .doc-archive {
    position: relative;
    display: block;
    box-sizing: content-box;
    margin: 0px;
    padding: 0.25em 0px 0px 4.25em;

    .uins-file {
      padding-right: 0px;
    }
  }
}
</style>
