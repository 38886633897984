export default {
  isString: function(url) {
    return url + "" === url ? true : false;
  },

  checkUrl: function(url) {
    return url + "" === url || url === "" ? url : " "; //checking if url is a string and always return a non empty string
  },

  //gets the protocol part of the url (http: or https:)
  getUrlProtocol: function() {
    return window.location.protocol;
  },

  //gets the domain part of the url (.something.com)
  getUrlDomain: function() {
    return window.location.hostname;
  },

  //gets the port
  getUrlPort: function() {
    return window.location.port;
  },

  //gets the query part of the url and removes the question mark from it
  getUrlQuery: function() {
    const splitLocation = window.location.toString().split("?");
    const queryString = splitLocation.length > 1 ? splitLocation[1] : "";
    const noHashQuery = queryString.split("#");
    return noHashQuery.length > 0 ? noHashQuery[0] : "";
  },

  // returns an object with name value properties
  objFromNameValueString: function(nameValueStr) {
    const nameValueArr = this.isString(nameValueStr)
      ? nameValueStr.split("=")
      : [];
    const name = this.isString(nameValueArr[0]) ? nameValueArr[0] : "";
    const value = this.isString(nameValueArr[1]) ? nameValueArr[1] : null;
    return {
      paramName: name,
      paramValue: value
    };
  },

  // returns an array with objects containing paramName & paramValue
  getUrlParamsFromQuery: function(urlQuery) {
    const splitQuery = urlQuery.toString().split("&");
    const result = {};
    for (let i = 0; i < splitQuery.length; i++) {
      let obj = this.objFromNameValueString(splitQuery[i]);
      if (this.isString(obj.paramName) && obj.paramName.length > 0) {
        result[obj.paramName] = obj.paramValue;
      }
    }
    return result;
  },

  getUrlParamValue: function(paramName, urlQuery) {
    const params = this.getUrlParamsFromQuery(urlQuery);
    return Object.prototype.hasOwnProperty.call(params, paramName)
      ? params[paramName]
      : null;
  },

  getLocationParamValue: function(paramName) {
    const query = this.getUrlQuery();
    return this.getUrlParamValue(paramName, query);
  }
};
