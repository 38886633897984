<template>
  <div class="uins-docs">
    <!--Legal></Legal-->
    <Loader></Loader>

    <label class="uins-doc-label" v-if="searchIsVisible">
      <input
        class="uins-doc-search"
        type="text"
        v-model.trim="searchText"
        placeholder="dokumentum keresés"
      />
      <SuggestedYears
        :years="yearsInDocsSearched"
        :selectedYear="narrowYear"
        :appId="appId"
      ></SuggestedYears>
      <!--button class="uins-doc-button">Keres</!--button-->
      <slide-up-down
        tag="div"
        class="uins-doc-no-result"
        :active="noResultsFound"
        >Nincs találat.</slide-up-down
      >
      <SuggestedLabels
        :labels="labelsFromDocsFiltered"
        :search="searchText"
        :appId="appId"
      ></SuggestedLabels>
    </label>
    <div class="uins-doc-categories" v-if="categories.length > 0">
      <div
        class="uins-doc-label"
        v-for="(category, key) in categories"
        v-bind:key="key"
      >
        {{ category }}
      </div>
    </div>
    <div class="uins-doc-search-result">
      <Document
        v-for="(doc, index) in searchResultDocs"
        v-bind:key="index"
        v-bind:doc="doc"
        :filterYear="narrowYear"
      ></Document>
    </div>
  </div>
</template>

<script>
//import Legal from "./components/Legal.vue";
import SuggestedLabels from "./components/SuggestedLabels.vue";
import SuggestedYears from "./components/SuggestedYears.vue";
import Document from "./components/Document.vue";
//import Vue from "vue";
import { EventBus } from "@/main.js";
import { mapGetters, mapActions } from "vuex";
import docsHandler from "@/plugins/DocumentsHandler.js";
import objHandler from "@/plugins/ObjectHandler.js";

export default {
  name: "App",
  components: {
    //Legal,
    SuggestedLabels,
    SuggestedYears,
    Document
  },
  created: function() {
    const sendData = {
      ids: "",
      labels: ""
    };
    //load the documents data
    this.loadDocumentsFromWeb({ sendData: sendData });
    this.loadDocsFromSmartCube({ sendData: sendData });
    //this.$log.debug("vm => ", this.$root.$data.filterIds);

    // evenets handling
    EventBus.$on("searchSuggestion", this.searchSuggestion);
    EventBus.$on("narrowSearchByYear", this.setNarrowYear);
  },

  beforeDestroy: function() {
    EventBus.$off("searchSuggestion");
  },

  data: function() {
    return {
      title: process.env.VUE_APP_TITLE,
      searchText: "",
      narrowYear: null //+ "some title" + this.$root.$el.outerHTML
    };
  },

  watch: {
    yearsInDocsSearched: function(newVal) {
      if (newVal.length === 0) {
        this.narrowYear = null;
      }
    }
  },

  computed: {
    ...mapGetters("api", {
      getApiDocumentsWeb: "getApiDocumentsWeb",
      getApiDocsSmartCube: "getApiDocsSmartCube"
    }),

    idsFromSmartCubeDocs: function() {
      return docsHandler.getIdsFromDocs(this.getApiDocsSmartCube);
    },

    filteredWebDocs: function() {
      return docsHandler.filterWithoutiIds(
        objHandler.deepCopy(this.getApiDocumentsWeb),
        this.idsFromSmartCubeDocs
      );
    },

    // mark documents from union.huby adding fromWeb property
    markedWebDocs: function() {
      return this.filteredWebDocs.map(doc => {
        doc.fromWeb = true;
        return doc;
      });
    },

    mergedDocs: function() {
      return docsHandler.mergeDocs(
        objHandler.deepCopy(this.getApiDocsSmartCube),
        this.markedWebDocs
      );
    },

    apiDocumentLabels: function() {
      const allLabels = this.docsPreFiltered.map(function(doc) {
        return objHandler.getProperty(false, "labels", doc);
      });
      const flattened = [].concat.apply([], allLabels);
      return objHandler.filterDuplicateValues(flattened);
    },

    appId: function() {
      return this.$root.$data.id;
    },

    myProp: function() {
      //this.$log.debug("vm => ", this.$root);
      return this.$root;
    },

    searchIsVisible: function() {
      return this.searchEnabled && this.documentsAreLoaded;
    },

    documentsAreLoaded: function() {
      return this.getApiDocumentsWeb.length > 0;
    },

    searchEnabled: function() {
      //return parseInt(this.$root.$data.search) === 1 ? true : false;
      return this.$root.$data.search;
    },

    // returns array of id's
    filterIds: function() {
      const ids = this.$root.$data.filterIds;
      return Array.isArray(ids) ? ids : [];
      //return ids !== null ? this.$root.$data.filterIds.split(",") : [];
    },

    // returns array of labels
    filterLabels: function() {
      const labels = this.$root.$data.filterLabels;
      return Array.isArray(labels) ? labels.map(item => item.split("+")) : [];
      //const prepared = Array.isArray(labels) ? labels.join("|").split("|");
      //return labels !== null ? this.$root.$data.filterLabels.split(",") : [];
    },

    categories: function() {
      const categories = this.$root.$data.categories;
      return Array.isArray(categories) ? categories : [];
      //return categories !== null ? this.$root.$data.categories.split(",") : [];
    },

    searchStarted: function() {
      return this.searchText.length > 1;
    },

    noResultsFound: function() {
      return this.searchResultDocs.length < 1 && this.searchStarted;
    },

    docsPreFilteredByIds: function() {
      //return docsHandler.filterByIds(
      //this.$log.debug(this.mergedDocs, this.filterIds);
      return docsHandler.markByIds(this.mergedDocs, this.filterIds);
    },

    docsPreFilteredByLabels: function() {
      return docsHandler.markByLabels(
        this.docsPreFilteredByIds,
        this.filterLabels
      );
    },

    docsPreFiltered: function() {
      const filteredByLabels = this.docsPreFilteredByLabels.filter(
        doc => doc.keep === true
      );
      const showMerged =
        filteredByLabels.length === 0 &&
        this.filterIds.length === 0 &&
        this.filterLabels.length === 0;
      return showMerged ? this.mergedDocs : filteredByLabels;
    },

    docsSearchedByTitle: function() {
      return docsHandler.searchDocumentsByTitle(
        this.docsPreFiltered,
        this.searchText
      );
    },

    docsSearchedByLabel: function() {
      return docsHandler.searchDocumentsByLabel(
        this.docsPreFiltered,
        this.searchText
      );
    },

    docsSearched: function() {
      return this.searchEnabled
        ? this.docsSearchedByLabel
        : this.docsPreFiltered; //docsPreFiltered;
      //return this.docsSearchedByTitle;
    },

    yearsInDocsSearched: function() {
      //this.$log.debug("docsSearched", this.docsSearched);
      const years = docsHandler.getYearsInDocuments(this.docsSearched);
      //this.narrowYear = years.length === 0 ? null : this.narrowYear;
      return years;
    },

    searchTitles: function() {
      return this.searchResultDocs.reduce(function(accu, doc) {
        return accu + " " + objHandler.getProperty("", "title", doc);
      }, ""); // !!! the last parameter is the initial value
    },

    searchLabels: function() {
      //const tmp = docsHandler.getLabelsWithCharsFromString()
      return this.searchResultDocs.reduce(function(accu, doc) {
        return accu + " " + objHandler.getProperty([], "labels", doc);
      }, ""); // !!! the last parameter is the initial value
    },

    /*
    wordsFromDocsFiltered: function() {
      return !this.searchStarted
        ? []
        : docsHandler.getWordsWithCharsFromString(
            this.searchTitles,
            this.searchText
          );
    },
*/

    labelsFromDocsFiltered: function() {
      return !this.searchStarted
        ? []
        : docsHandler.getLabelsWithCharsFromDocs(
            this.docsSearched,
            this.searchText
          );
    },

    searchNarrowedByYear: function() {
      if (this.narrowYear === null) {
        return this.docsSearched;
      } else {
        return this.docsSearched;
      }
    },

    searchResultDocs: function() {
      return this.searchNarrowedByYear;
    }
  },
  methods: {
    ...mapActions("api", {
      loadDocumentsFromWeb: "loadDocumentsFromWeb",
      loadDocsFromSmartCube: "loadDocsFromSmartCube"
    }),

    searchSuggestion: function(suggestedWord, appId) {
      //this.$log.debug(suggestedWord);
      //this.$log.debug(this.appId, appId);
      if (this.appId === appId) {
        this.searchText = suggestedWord;
      }
    },

    setNarrowYear: function(selectedYear, appId) {
      if (this.appId === appId) {
        this.narrowYear = selectedYear;
      }
    }
  }
};
</script>

<style lang="scss">
$maxWidth: 1280px;

.uins-docs {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 1em auto 0px auto;
  padding: 0px;
  max-width: $maxWidth;

  .uins-doc-label {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1rem;
    text-align: center;
    //min-width: 18em;
    min-width: 80%;
    max-width: 32em;
    height: auto;
    margin: 0px auto 0px auto;
    padding: 0px;

    .uins-doc-search {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      font-size: 1.25em;
      width: 100%;
      height: 2.1em;
      margin: 0px;
      padding: 0.25em 4em 0.25em 0.25em;
      vertical-align: middle;
      font-family: $font-family-light;
      color: $colorGrayDark;
      line-height: 110%;
      border: 1px solid $colorGrayLight;
      border-radius: 4px;

      //border-top-left-radius: 4px;
      //border-bottom-left-radius: 4px;

      &::placeholder {
        color: $colorGrayLight;
      }

      &:focus {
        outline-style: none;
        box-shadow: none;
        border-color: $colorRed;
      }
    }

    .uins-doc-button {
      position: relative;
      display: none;
      box-sizing: border-box;
      font-size: 1em;
      height: 2.625em;
      margin: 0px auto 0px auto;
      padding: 0.75em 1.5em 0.25 1.5em;
      vertical-align: middle;
      font-family: $font-family-light;
      color: $colorWhite;
      background-color: $colorGrayLight;
      line-height: 110%;
      border: 1px solid $colorGrayLight;
      border-left: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        text-shadow: 0px 0px 0.5em $colorWhite;
      }

      &:focus {
        outline-style: none;
        box-shadow: none;
        border: 1px solid $colorRed;
      }

      &:active {
        background-color: $colorGray;
        box-shadow: 0px 5px 0.25em 0em $colorGray inset;
      }
    }

    .uins-doc-no-result {
      position: relative;
      display: block;
      box-sizing: border-box;
      font-size: 1em;
      width: 10em;
      margin: 0px auto 0px auto;
      padding: 0.75em 1.5em 0.25 1.5em;
      text-align: center;
      color: $colorWhite;
      background-color: $colorGray;
      font-weight: normal;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .uins-doc-search-result {
    position: relative;
    display: block;
    box-sizing: border-box;
    margin: 0 auto 0 auto;
    padding: 1em 0px 0px 0px;
    text-align: left;
    max-width: 100%;
  }
}
</style>
