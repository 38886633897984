import Vue from "vue";
import Vuex from "vuex";
import storeLoader from "@/store/modules/storeLoader";
import storeApi from "@/store/modules/storeApi";

Vue.use(Vuex);

export default new Vuex.Store({
  // adding pugins
  //plugins: [ObjectHandler],

  // storing all the properties of our application
  state: {},

  // getter for the state properties are used for accessing them
  getters: {},

  // mutating, changing the the state properties
  // !!! IMPORTANT: mutations are and should be always syncronous
  // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
  mutations: {},

  // actions. methods for triggering mutations
  actions: {},

  //modules with separate store data structure
  modules: {
    loader: storeLoader,
    api: storeApi
  }
});
