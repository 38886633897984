// string utilities
// methods for searching and handling strings

// search string for searchText
export default {
  // check a variable if string and always return string
  forceString: function(str) {
    return str === str + "" ? str : "";
  },

  searchStringForText: function(str, searchText) {
    const searchString = this.forceString(searchText);
    const regex = RegExp(this.escapeRegExp(searchString), "gi");
    return searchString === "" ? false : regex.test(this.forceString(str));
  },

  // escape  string for regexp
  escapeRegExp: function(string) {
    //return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  },

  // remove unneeded words and stringparts from title string
  // this is usefull if searching for words in titles
  cleanTitle: function(title) {
    const strTitle = title + "" === title ? title : "";
    const reg = new RegExp(
      "\\(|\\)|\"|'|„|”|-,|–,|,| -| –|- és |– és | a | és | az | vagy ",
      "gi"
    );
    return strTitle.replace(reg, " ");
  },

  getWordsWithCharsFromString: function(str, chars) {
    const cleanStr = this.cleanTitle(str);
    //console.log(cleanStr);
    const strArray = cleanStr.toUpperCase().split(" ");
    const escChars = this.escapeRegExp(chars);
    const chrReg = new RegExp(".*" + escChars, "gi"); //new RegExp("^" + escChars + "|.*[\\-|–].*" + escChars, "gi");
    var keys = {};
    const filtered = strArray.filter(function(word) {
      const isValid = chrReg.test(word);
      const isFound = Object.prototype.hasOwnProperty.call(keys, word);
      if (!isFound && isValid) {
        keys[word] = true;
      }
      return isValid && !isFound;
    }, this);
    const ordered = filtered
      .slice()
      .sort(this.orderAlphabetically(this.hungarianAphabet), this);
    return ordered;
  },

  //returns true if chars string is found inside word (case sensitive)
  wordHasChars: function(word, chars) {
    const index = chars !== "" ? word.indexOf(chars) : -1;
    return index >= 0;
  },

  orderAlphabetically: function(alphabet, dir, caseSensitive) {
    dir = dir || 1;
    function compareLetters(a, b) {
      var ia = alphabet.indexOf(a);
      var ib = alphabet.indexOf(b);
      if (ia === -1 || ib === -1) {
        if (ib !== -1) return a > "a";
        if (ia !== -1) return "a" > b;
        return a > b;
      }
      return ia > ib;
    }
    return function(a, b) {
      var pos = 0;
      var min = Math.min(a.length, b.length);
      caseSensitive = caseSensitive || false;
      if (!caseSensitive) {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }
      while (a.charAt(pos) === b.charAt(pos) && pos < min) {
        pos++;
      }
      return compareLetters(a.charAt(pos), b.charAt(pos)) ? dir : -dir;
    };
  },

  hungarianAphabet:
    "AaÁáBbCcDdEeÉéFfGgHhIiÍíJjKkLlMmNnOoÓóÖöŐőPpQqRrSsTtTytyUuÚúÜüŰűVvWwXxYyZz"
};
