// document single file handling plugin

// import heplers
import dateHandler from "@/plugins/DateHandler.js";
import objHandler from "@/plugins/ObjectHandler.js";

// exporting methods
export default {
  // returns all years from a document's files
  //getYearsFromFile: function(file) {
  getYearsList: function(file) {
    const yearFrom = Math.floor(this.getDateFrom(file) / 10000);
    const yearTo = Math.floor(this.getDateTo(file) / 10000);
    const countTo = yearTo >= yearFrom ? yearTo : yearFrom;
    const result = [];
    for (let i = yearFrom; i <= countTo; i++) {
      result.push(i);
    }
    return result;
  },

  //fileHasYear: function(file, year) {
  hasYear: function(file, year) {
    const years = this.getYearsList(file);
    return years.indexOf(year) >= 0;
  },

  //getStringFileDateFrom: function(file) {
  getStringDateFrom: function(file) {
    return objHandler.getProperty("", "datefrom", file);
  },

  //getStringFileDateTo: function(file) {
  getStringDateTo: function(file) {
    return objHandler.getProperty("", "dateto", file);
  },

  //getDateFrom: function(file) {
  getDateFrom: function(file) {
    const myDate = parseInt(this.getStringDateFrom(file), 10);
    return myDate + 0 === myDate ? myDate : dateHandler.getTodayInt();
  },

  //getFileDateTo: function(file) {
  getDateTo: function(file) {
    const dateFrom = this.getDateFrom(file);
    const myDate = parseInt(this.getStringDateTo(file), 10);
    const dateTo = myDate + 0 === myDate ? myDate : dateHandler.getTodayInt();
    return dateTo >= dateFrom ? dateTo : dateFrom;
  },

  //compareDocumentFiles: function(fileA, fileB) {
  compareDate: function(fileA, fileB) {
    return this.getDateFrom(fileA) - this.getDateFrom(fileB);
  },

  //filterFuture: function(file) {
  isFuture: function(file) {
    //console.log("isFuture", this);
    return this.getDateFrom(file) > dateHandler.getTodayInt();
  },

  //filterCurrent: function(file) {
  isCurrent: function(file) {
    return (
      this.getDateFrom(file) <= dateHandler.getTodayInt() &&
      this.getDateTo(file) >= dateHandler.getTodayInt()
    );
  },

  //filterExpired: function(file) {
  isExpired: function(file) {
    return this.getDateTo(file) < dateHandler.getTodayInt();
  }
};
