import ApiService from "@/services/ApiService";

export default {
  getDocumentsWebApi(sendData) {
    return ApiService(process.env.VUE_APP_API_WEB_BASEURL).post(
      process.env.VUE_APP_API_WEB_PATH + "index.php?t=23",
      sendData
    );
  },

  getDocsSmartCubeApi(sendData) {
    return ApiService(process.env.VUE_APP_API_SC_BASEURL).post(
      process.env.VUE_APP_API_SC_PATH + "",
      sendData
    );
  },

  getDocumentsSmartCubeApi(params) {
    params = params + "" === params ? params : "";
    return ApiService().get(process.env.VUE_APP_API_WEB_PATH + params);
  }
};
