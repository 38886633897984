// vuex store module
// intended for api functionality, used in conjunction with axios for loading api
import LoadService from "@/services/LoadService";
import ObjectHandler from "@/plugins/ObjectHandler";

export default {
  // mark as namespaced
  namespaced: true,

  // storing the loaded api properties of our application
  // the apiMenu is storing response from menu api
  // the apiPage is storing response from page api returning the content of one page
  state: {
    apiDocumentsWeb: [],
    apiDocsSmartCube: []
  },

  // getter for the state properties are used for accessing them
  getters: {
    // returns the current value of the apiDocuments state
    getApiDocumentsWeb: function(state) {
      return state.apiDocumentsWeb;
    },
    getApiDocsSmartCube: function(state) {
      return state.apiDocsSmartCube;
    }
  },

  // mutating, changing the the state properties
  // !!! IMPORTANT: mutations are and should be always syncronous
  // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
  mutations: {
    // changes the apiMenu state by replacing it with a new array
    setApiDocumentsWeb: function(state, newArray) {
      state.apiDocumentsWeb = ObjectHandler.returnArray(newArray);
    },
    setApiDocsSmartCube: function(state, newArray) {
      state.apiDocsSmartCube = ObjectHandler.returnArray(newArray);
    }
  },

  // actions. methods for triggering mutations
  actions: {
    // loads asynchronously the apiMenu
    async loadDocumentsFromWeb(context, { sendData }) {
      // gets acynchronously the documents XML
      // saves the recieved value to state by commiting mutation
      const response = await LoadService.getDocumentsWebApi(sendData);
      context.commit("setApiDocumentsWeb", response.data);
      // dispatching action is used for calling an action
      // context.dispatch("actionName");
    },

    async loadDocsFromSmartCube(context, { sendData }) {
      // gets acynchronously the documents XML
      // saves the recieved value to state by commiting mutation
      const response = await LoadService.getDocsSmartCubeApi(sendData);
      context.commit("setApiDocsSmartCube", response.data);
      // dispatching action is used for calling an action
      // context.dispatch("actionName");
    }
  }
};
