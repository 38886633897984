// plugin for handling multiple documents

// import plugins
import stringHandler from "@/plugins/StringHandler.js";
import objHandler from "@/plugins/ObjectHandler.js";
import docHandler from "@/plugins/DocumentHandler.js";

// import vuex
//import { mapGetters } from "vuex";

export default {
  //_documentsList: [],

  // merge two docs array into one array
  mergeDocs: function(docsA, docsB) {
    return docsA.concat(docsB);
  },

  // filtering the docs array based on labels array
  filterByLabels: function(docsArray, labelsArray) {
    return docsArray.filter(function(doc) {
      return docHandler.docHasAllLabels(doc, labelsArray);
    }, this);
  },

  // filtering the docs array based on labels array
  markByLabels: function(docsArray, labelsArray) {
    var result = docsArray;
    labelsArray.forEach(labels => {
      const temp = result.map(doc => {
        const keep = doc.keep || docHandler.docHasAllLabels(doc, labels);
        if (keep === true) {
          doc.keep = keep;
        }
        return doc;
      });
      result = temp;
    });

    return result;
  },

  // filtering the docs array based on ids array
  // returns array with docs having any id from list
  filterByIds: function(docsArray, idsArray) {
    return docsArray.filter(function(doc) {
      return docHandler.docHasAnyId(doc, idsArray);
    }, this);
  },

  // filtering the docs array based on ids array
  // returns array with docs having any id from list
  markByIds: function(docsArray, idsArray) {
    return docsArray.map(doc => {
      const newDoc = doc;
      const keep = newDoc.keep || docHandler.docHasAnyId(doc, idsArray);
      if (keep === true) {
        newDoc.keep = keep;
      }
      return newDoc;
    });
  },

  // filtering the docs array based on ids array
  // returns array with docs not having any id from list
  filterWithoutiIds: function(docsArray, idsArray) {
    return docsArray.filter(function(doc) {
      return docHandler.docHasAnyId(doc, idsArray) === false;
    }, this);
  },

  // search documents by name if search text is at least 2 caharacters long otherwise return empty array
  searchDocumentsByLabel: function(docsArray, searchText) {
    const searched = objHandler.forceString(searchText);
    return searched.length > 1
      ? this.filterByLabelsSearch(docsArray, searchText)
      : [];
  },

  // filtering the docs array based on seach text in labels
  filterByLabelsSearch: function(docsArray, searchText) {
    return docsArray.filter(function(doc) {
      const labels = objHandler.getProperty([], "labels", doc);
      const labelsString = labels.join(",");
      return stringHandler.searchStringForText(labelsString, searchText);
    }, this);
  },

  // search documents by name if search text is at least 2 caharacters long otherwise return empty array
  searchDocumentsByTitle: function(docsArray, searchText) {
    const searched = objHandler.forceString(searchText);
    return searched.length > 1
      ? this.filterByTitleSearch(docsArray, searchText)
      : [];
  },

  // filtering the docs array based on seach text in title
  filterByTitleSearch: function(docsArray, searchText) {
    return docsArray.filter(function(doc) {
      return docHandler.searchDocumentTitle(doc, searchText);
    }, this);
  },

  // retrurns years for an array of documents
  getYearsInDocuments: function(docsArray) {
    //const self = this;
    const docsChecked = objHandler.forceArray(docsArray);
    const docDates = docsChecked.map(function(doc) {
      return docHandler.getYearsInDocument(doc);
    }, this);
    const flattened = objHandler.flattenArray(docDates);
    const noDuplicates = objHandler.filterDuplicateValues(flattened);
    const sorted = noDuplicates.sort((a, b) => b - a);
    return sorted;
  },

  //returns an array of labels
  getIdsFromDocs: function(docs) {
    const ids = objHandler.getEachPropVal("id", docs);
    const flattened = objHandler.flattenArray(ids);
    const noDuplicates = objHandler.filterDuplicateValues(flattened);
    return noDuplicates.map(id => id + "");
  },

  //returns an array of labels
  getLabelsFromDocs: function(docs) {
    const labels = objHandler.getEachPropVal("labels", docs);
    const flattened = objHandler.flattenArray(labels);
    const noDuplicates = objHandler.filterDuplicateValues(flattened);
    return noDuplicates;
  },

  getLabelsWithCharsFromDocs: function(docs, chars) {
    const charsUppercase = chars.toUpperCase();
    const labels = this.getLabelsFromDocs(docs);
    const filtered = labels.filter(function(word) {
      const wordUppercase = word.toUpperCase();
      return stringHandler.wordHasChars(wordUppercase, charsUppercase);
    }, this);
    const ordered = filtered
      .slice()
      .sort(
        stringHandler.orderAlphabetically(stringHandler.hungarianAphabet),
        this
      );
    return ordered;
  }
};
